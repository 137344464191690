// TaskItem.js
import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import { CgArrowLongLeftC, CgArrowLongRightC } from "react-icons/cg"; // Import arrow icons from react-icons

const TaskItem = ({ task, updatePriority, deleteTask }) => {
  const [swipePhase, setSwipePhase] = useState('importance'); // Two phases: 'importance' and 'urgency'
  const [bgColor, setBgColor] = useState(''); // State to manage background color during swiping
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    setDarkMode(mediaQuery.matches);

    const handleThemeChange = (event) => {
      setDarkMode(event.matches);
    };

    mediaQuery.addEventListener('change', handleThemeChange);

    return () => {
      mediaQuery.removeEventListener('change', handleThemeChange);
    };
  }, []);

  const handlers = useSwipeable({
    onSwipedLeft: () => handleSwipe('left'),
    onSwipedRight: () => handleSwipe('right'),
    onSwiping: (eventData) => handleSwiping(eventData),
    trackMouse: true, // Track mouse events to support desktop
  });

  // Handle the swipe action
  const handleSwipe = (direction) => {
    // Reset background color after swipe
    setBgColor('');

    if (swipePhase === 'importance') {
      if (direction === 'left') {
        updatePriority(task.id, 'important', false); // Not important
      } else if (direction === 'right') {
        updatePriority(task.id, 'important', true); // Important
      }
      // Move to urgency phase after swiping left or right
      setSwipePhase('urgency');
    } else if (swipePhase === 'urgency') {
      if (direction === 'left') {
        updatePriority(task.id, 'urgent', false); // Not urgent
      } else if (direction === 'right') {
        updatePriority(task.id, 'urgent', true); // Urgent
      }
    }
  };

  // Handle swiping to change background color
  const handleSwiping = (eventData) => {
    if (swipePhase === 'importance') {
      if (eventData.dir === 'Right') {
        setBgColor('rgba(144, 238, 144, 0.5)'); // Light green for Important
      } else if (eventData.dir === 'Left') {
        setBgColor('rgba(255, 99, 71, 0.5)'); // Light red for Not Important
      }
    } else if (swipePhase === 'urgency') {
      if (eventData.dir === 'Right') {
        setBgColor('rgba(144, 238, 144, 0.5)'); // Light green for Urgent
      } else if (eventData.dir === 'Left') {
        setBgColor('rgba(255, 99, 71, 0.5)'); // Light red for Not Urgent
      }
    }
  };

  return (
    <div
      {...handlers}
      className={`task-item p-4 rounded-md shadow-md transition-colors duration-300 ease-in-out ${darkMode ? 'bg-gray-800 text-white border-gray-600' : 'bg-gray-100 text-gray-800 border-gray-300'}`}
      style={{ backgroundColor: bgColor }}
    >
      <button
        className={`delete-task text-xl cursor-pointer p-1 rounded-md transition-colors duration-300 ease-in-out ${darkMode ? 'text-white hover:text-gray-400' : 'text-gray-800 hover:text-red-600'}`}
        onClick={(e) => {
          e.stopPropagation(); // Prevent triggering swipe event when clicking delete
          deleteTask(task.id); // Delete task
        }}
      >
        ✕
      </button>
      <div className={`swipe-instructions ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
        {swipePhase === 'importance' ? (
          <>
            <span className="swipe-arrow">
              <CgArrowLongLeftC /> Not Important
            </span>
            <span className="swipe-arrow">
              Important <CgArrowLongRightC />
            </span>
          </>
        ) : (
          <>
            <span className="swipe-arrow">
              <CgArrowLongLeftC /> Not Urgent
            </span>
            <span className="swipe-arrow">
              Urgent <CgArrowLongRightC />
            </span>
          </>
        )}
      </div>
      <p className={`mt-2 transition-colors duration-300 ease-in-out ${darkMode ? 'text-white' : 'text-gray-800'}`}>
        {task.text}
      </p>
    </div>
  );
};

export default TaskItem;
