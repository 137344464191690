// TaskList.js
import React, { useEffect, useState } from 'react';
import TaskItem from './TaskItem';

const TaskList = ({ tasks, updatePriority, deleteTask }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    setIsDarkMode(mediaQuery.matches);

    const handleThemeChange = (event) => {
      setIsDarkMode(event.matches);
    };

    mediaQuery.addEventListener('change', handleThemeChange);

    return () => {
      mediaQuery.removeEventListener('change', handleThemeChange);
    };
  }, []);

  return (
    <div className={`task-list p-4 rounded-lg shadow-md mb-8 transition-colors duration-300 ease-in-out ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-gray-50 text-gray-800'}`}>
      <h2 className={`category-title text-xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>Tasks</h2>
      {tasks.length > 0 ? (
        tasks.map((task) => (
          <TaskItem 
            key={task.id} 
            task={task} 
            updatePriority={updatePriority}
            deleteTask={deleteTask}  // Passing deleteTask to each TaskItem
          />
        ))
      ) : (
        <p className={`text-sm italic ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>No tasks available. Add a task to get started!</p>
      )}
    </div>
  );
};

export default TaskList;
