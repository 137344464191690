import React, { useState, useEffect } from 'react';
import { CgCalendarToday } from "react-icons/cg";
import TaskInput from './TaskInput';
import TaskList from './TaskList';
import './App.css';

const App = () => {
  const getFromLocalStorage = (key, defaultValue = []) => {
    const storedData = localStorage.getItem(key);
    return storedData ? JSON.parse(storedData) : defaultValue;
  };

  const [tasks, setTasks] = useState(() => getFromLocalStorage('tasks'));
  const [doNow, setDoNow] = useState(() => getFromLocalStorage('doNow'));
  const [schedule, setSchedule] = useState(() => getFromLocalStorage('schedule'));
  const [delegate, setDelegate] = useState(() => getFromLocalStorage('delegate'));
  const [archive, setArchive] = useState(() => getFromLocalStorage('archive'));
  const [completedTasks, setCompletedTasks] = useState(() => getFromLocalStorage('completedTasks'));
  const [showCompleted, setShowCompleted] = useState(false);
  const [darkMode, setDarkMode] = useState(false);

  // Automatically detect system dark mode preference
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    setDarkMode(mediaQuery.matches);

    const handleChange = (event) => {
      setDarkMode(event.matches);
    };

    mediaQuery.addEventListener('change', handleChange);

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem('tasks', JSON.stringify(tasks));
    localStorage.setItem('doNow', JSON.stringify(doNow));
    localStorage.setItem('schedule', JSON.stringify(schedule));
    localStorage.setItem('delegate', JSON.stringify(delegate));
    localStorage.setItem('archive', JSON.stringify(archive));
    localStorage.setItem('completedTasks', JSON.stringify(completedTasks));
  }, [tasks, doNow, schedule, delegate, archive, completedTasks]);

  const addTask = (taskText) => {
    const newTask = {
      id: Date.now() + Math.random(),
      text: taskText,
      priority: { important: null, urgent: null }
    };
    setTasks((prevTasks) => [...prevTasks, newTask]);
  };

  const isTaskInList = (list, taskId) => list.some((task) => task.id === taskId);

  const categorizeTask = (task) => {
    const { important, urgent } = task.priority;

    if (important === true && urgent === true && !isTaskInList(doNow, task.id)) {
      setDoNow((prevDoNow) => [...prevDoNow, task]);
    } else if (important === true && urgent === false && !isTaskInList(schedule, task.id)) {
      setSchedule((prevSchedule) => [...prevSchedule, task]);
    } else if (important === false && urgent === true && !isTaskInList(delegate, task.id)) {
      setDelegate((prevDelegate) => [...prevDelegate, task]);
    } else if (important === false && urgent === false && !isTaskInList(archive, task.id)) {
      setArchive((prevArchive) => [...prevArchive, task]);
    }
  };

  const handleAddToCalendar = (task) => {
    const taskText = encodeURIComponent(task.text);

    if (/Android/i.test(navigator.userAgent)) {
      const eventUrl = `intent://calendar.google.com/calendar/render?action=TEMPLATE&text=${taskText}#Intent;scheme=https;package=com.google.android.calendar;end`;
      window.location.href = eventUrl;
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      const eventUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${taskText}`;
      window.open(eventUrl, '_blank');
    } else {
      const eventUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${taskText}`;
      window.open(eventUrl, '_blank');
    }
  };

  const updatePriority = (id, type, value) => {
    setTasks((prevTasks) => {
      return prevTasks.reduce((acc, task) => {
        if (task.id === id) {
          const updatedTask = { ...task, priority: { ...task.priority, [type]: value } };
          const { important, urgent } = updatedTask.priority;

          if (important !== null && urgent !== null) {
            categorizeTask(updatedTask);
            return acc;
          }
          return [...acc, updatedTask];
        }
        return [...acc, task];
      }, []);
    });
  };

  const markAsComplete = (id, category) => {
    let updatedCategory = [];
    let completedTask = null;

    switch (category) {
      case 'doNow':
        updatedCategory = doNow.filter((task) => {
          if (task.id === id) {
            completedTask = task;
            return false;
          }
          return true;
        });
        setDoNow(updatedCategory);
        break;
      case 'schedule':
        updatedCategory = schedule.filter((task) => {
          if (task.id === id) {
            completedTask = task;
            return false;
          }
          return true;
        });
        setSchedule(updatedCategory);
        break;
      case 'delegate':
        updatedCategory = delegate.filter((task) => {
          if (task.id === id) {
            completedTask = task;
            return false;
          }
          return true;
        });
        setDelegate(updatedCategory);
        break;
      case 'archive':
        updatedCategory = archive.filter((task) => {
          if (task.id === id) {
            completedTask = task;
            return false;
          }
          return true;
        });
        setArchive(updatedCategory);
        break;
      default:
        return;
    }

    if (completedTask) {
      setCompletedTasks((prevCompletedTasks) => [...prevCompletedTasks, completedTask]);
    }
  };

 const deleteTask = (id) => {
    setTasks((prevTasks) => prevTasks.filter((task) => task.id !== id));
    setDoNow((prevDoNow) => prevDoNow.filter((task) => task.id !== id));
    setSchedule((prevSchedule) => prevSchedule.filter((task) => task.id !== id));
    setDelegate((prevDelegate) => prevDelegate.filter((task) => task.id !== id));
    setArchive((prevArchive) => prevArchive.filter((task) => task.id !== id));
    setCompletedTasks((prevCompletedTasks) => prevCompletedTasks.filter((task) => task.id !== id));
  };

  const handleRightClickDelete = (e, id) => {
    e.preventDefault(); 
    if (window.confirm("Are you sure you want to delete this task?")) {
      deleteTask(id);
    }
  };

  return (
    <div className={`app px-4 ${darkMode ? 'dark' : ''}`}>
      <div className="main-content flex flex-col md:flex-row md:justify-between gap-8">
        <div className="task-input-section md:w-1/2">
          <TaskInput addTask={addTask} />
          <TaskList
            tasks={tasks.filter((task) => task !== null)}
            updatePriority={updatePriority}
            deleteTask={deleteTask}
          />
        </div>

        <div className={`prioritized-lists md:w-1/2 p-4 rounded-lg shadow-md ${darkMode ? 'bg-gray-800 text-white' : 'bg-white'}`}>
  {doNow.length > 0 && (
    <div className={`category-card mb-8 p-4 rounded-lg shadow-md ${darkMode ? 'bg-gray-700 text-white' : 'bg-white'}`}>
      <h2 className="category-title text-xl font-bold mb-4">Do Now</h2>
      {doNow.map((task) => (
        <div 
          key={`doNow-${task.id}`} 
          className={`simple-task-card ${darkMode ? 'simple-task-card dark' : 'simple-task-card light'}`} 
          onContextMenu={(e) => handleRightClickDelete(e, task.id)}
        >
          <input type="checkbox" className="checkbox mr-2" onChange={() => markAsComplete(task.id, 'doNow')} />
          <span>{task.text}</span>
        </div>
      ))}
    </div>
  )}

  {schedule.length > 0 && (
    <>
      <h2 className="category-title text-xl font-bold mb-4 mt-8">Schedule</h2>
      {schedule.map((task) => (
        <div
          key={`schedule-${task.id}`}
          className={`schedule-task-card ${darkMode ? 'schedule-task-card dark' : 'schedule-task-card light'}`}
          onContextMenu={(e) => handleRightClickDelete(e, task.id)}
        >
          <div className="flex items-center">
            <input type="checkbox" className="checkbox mr-2" onChange={() => markAsComplete(task.id, 'schedule')} />
            <span className="flex-grow">{task.text}</span>
          </div>
          <button
            className="calendar-button"
            onClick={() => handleAddToCalendar(task)}
          >
            <CgCalendarToday size="1.5rem" />
          </button>
        </div>
      ))}
    </>
  )}

  {delegate.length > 0 && (
    <div className={`category-card mb-8 p-4 rounded-lg shadow-md ${darkMode ? 'bg-gray-700 text-white' : 'bg-white'}`}>
      <h2 className="category-title text-xl font-bold mb-4">Delegate</h2>
      {delegate.map((task) => (
        <div 
          key={`delegate-${task.id}`} 
          className={`simple-task-card ${darkMode ? 'simple-task-card dark' : 'simple-task-card light'}`} 
          onContextMenu={(e) => handleRightClickDelete(e, task.id)}
        >
          <input type="checkbox" className="checkbox mr-2" onChange={() => markAsComplete(task.id, 'delegate')} />
          <span>{task.text}</span>
        </div>
      ))}
    </div>
  )}

  {archive.length > 0 && (
    <div className={`category-card mb-8 p-4 rounded-lg shadow-md ${darkMode ? 'bg-gray-700 text-white' : 'bg-white'}`}>
      <h2 className="category-title text-xl font-bold mb-4">Nope</h2>
      {archive.map((task) => (
        <div 
          key={`archive-${task.id}`} 
          className={`simple-task-card ${darkMode ? 'simple-task-card dark' : 'simple-task-card light'}`} 
          onContextMenu={(e) => handleRightClickDelete(e, task.id)}
        >
          <input type="checkbox" className="checkbox mr-2" onChange={() => markAsComplete(task.id, 'archive')} />
          <span>{task.text}</span>
        </div>
      ))}
    </div>
  )}
  <div className="mt-8">
    <div
      className="cursor-pointer category-title text-xl font-bold mb-4 mt-8"
      onClick={() => setShowCompleted((prevShowCompleted) => !prevShowCompleted)}
    >
      {showCompleted ? '▼ Completed Tasks' : '▶ Completed Tasks'}
    </div>
    {showCompleted && (
      <div>
        {completedTasks.length > 0 ? (
          completedTasks.map((task) => (
            <div 
              key={`completed-${task.id}`} 
              className={`mb-4 p-4 shadow-md rounded-md ${darkMode ? 'bg-gray-700 text-white' : 'bg-white'}`} 
              onContextMenu={(e) => handleRightClickDelete(e, task.id)}
            >
              <span>{task.text} (Completed)</span>
            </div>
          ))
        ) : (
          <p className="empty-state">No completed tasks yet.</p>
        )}
      </div>
    )}
  </div>
</div>
</div>

        </div>
    
  );
};

export default App;
